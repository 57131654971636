import React from 'react'
import { useParams } from 'react-router-dom'
import Content from '../components/ui-shell/Content'
import S3Explorer from '../components/s3-explorer/s3Explorer'

const mapeo = {
    "world-feed-elements-grapics-and-logos": "ftp/elements/laliganews/1. World feed elements, Graphics and logos",
    "broadcasts-obligation-elements": "ftp/elements/laliganews/2. Broadcast Oblication Elements",
    "laliga-official-anthem": "ftp/elements/laliganews/4. LaLiga Official Anthem",
    "videoday-materials": "ftp/elements/laliganews/5. Videoday Materials",
    "starter-pack-and-promotional-materials": "ftp/elements/laliganews/6. Starter Pack and Promotional Materials",
    "music-cue-sheet": "ftp/elements/laliganews/7. Music Cue Sheets"
    //"laliga-brand-assets": 'ftp/elements/03 Additional Material LaLiga Santander/03 GRAPHICS TEMPLATES/TEAM_LOGOS_ANIMATED/'
}

const ExplorerPage = ({
    token,
}) => {
    const { path } = useParams();
    const s3Prefix = mapeo[path];
    console.log(s3Prefix)

    return (
        <Content className="content--videos content--videos-details">
            <S3Explorer path={s3Prefix} token={token} />
        </Content>
    )
}


export default ExplorerPage
