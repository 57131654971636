import React, { Component } from 'react'
import FileBrowser, { Icons, FileRenderers } from 'react-keyed-file-browser'
import WindowSpinner from '../WindowSpinner/WindowSpinner'

import 'react-keyed-file-browser/dist/react-keyed-file-browser.css'
import 'font-awesome/css/font-awesome.min.css'
import apiClient from '../../utils/apiClient'


class MyFile extends FileRenderers.RawTableFile {
    handleFileClick = (event) => {
        if (event && event.preventDefault) {
            event.preventDefault()
            const url = 'https://static.laliganews.es/' + this.props.path + this.props.fileKey
            window.open(url, '_blank').focus()

        }
    }
}




export default class S3Explorer extends Component {
    state = {
        isFetching: true,
        files: [],
    }

    fetchS3 = async () => {
        const files = await apiClient('POST', process.env.REACT_APP_API_URL + process.env.REACT_APP_VERSION, '/explorer?token='+this.props.token, { id: this.props.path });
        this.setState({ files: files, isFetching: false })


        /*/
                s3.listObjects(params, (err, data) => {
                    if (err) throw err
                    console.log(data)
                    if (data.Contents) {
                        const files = []
                        for (const content of data.Contents) {
                            if (content.Size > 0) {
                                const obj = {
                                    key: content.Key.replace(this.props.path, ''),
                                    size: content.Size,
                                    modified: +Moment(content.LastModified),
                                    path: this.props.path
                                }
                                files.push(obj)
                            }
                        }
                        this.setState({ files: files, isFetching: false })
                    }
                })
                */

    }

    async componentDidMount() {
        if (this.props.path) {
            this.fetchS3()
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {
            this.fetchS3()
        }
    }




    render() {
        return (
            <div className="grid">
                {
                    !this.state.isFetching ?
                        <FileBrowser
                            files={this.state.files}
                            icons={Icons.FontAwesome(4)}
                            fileRenderer={MyFile}
                        /> : <WindowSpinner background="#171717" visible={this.state.isFetching } />

                }

            </div>
        )
    }
}
